import React, { useState, useEffect } from 'react';

function Clock({ time }) {
  // Split the time string into hours and minutes
  const [hours, minutes] = time.split(":").map(Number);

  // Initialize state to hold the current time
  const [currentTime, setCurrentTime] = useState(new Date().setHours(hours, minutes, 0, 0));

  // useEffect hook to update the current time and provided time at the start of each new minute
  useEffect(() => {
    // Function to update the current time and provided time
    function updateTime() {
      const nextMinute = new Date(currentTime);
      nextMinute.setMinutes(nextMinute.getMinutes() + 1); // Add 1 minute
      setCurrentTime(nextMinute);

      // Update provided time
      const nextProvidedTime = new Date(currentTime);
      nextProvidedTime.setMinutes(nextProvidedTime.getMinutes() + 1);
      time = `${String(nextProvidedTime.getHours()).padStart(2, "0")}:${String(nextProvidedTime.getMinutes()).padStart(2, "0")}`;
    }

    const now = new Date();
    const secondsUntilNextMinute = 60 - now.getSeconds();
    const millisecondsUntilNextMinute = secondsUntilNextMinute * 1000 - now.getMilliseconds();

    const timerID = setTimeout(() => {
      updateTime();

      const intervalID = setInterval(updateTime, 60000);

      return () => clearInterval(intervalID);
    }, millisecondsUntilNextMinute);


    return () => clearTimeout(timerID);
  }, [currentTime, time]); 

  const formattedTime = new Date(currentTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

  return (
    <>
      <p>{formattedTime}</p>
    </>
  );
}

export default Clock;