import React, { useState, useEffect } from 'react';
import "../css/banner.scss";
import moment from 'moment-timezone';
import Clock from './clock';

import bannerData from "../assets/banner-visuels/Hero-Data.png";
import bannerJew from "../assets/banner-visuels/Hero-Jew.png";
import bannerRetail from "../assets/banner-visuels/Hero-Retail.png";
import bannerManu from "../assets/banner-visuels/Hero-Manu.png";
import bannerAuto from "../assets/banner-visuels/Hero-Auto.png";
import bannerMedia from "../assets/banner-visuels/Hero-Media.png"
import bannerDesign from "../assets/banner-visuels/Hero-Design.png"

function Banner({ layout, TimerPosition, Pagetype, Timezone }) {
  const [currentTime, setCurrentTime] = useState('');
  const [timezone, setTimezone] = useState('');
  const [content, setContent] = useState(null);

  useEffect(() => {
    // Fetching current time for New York timezone
    const newYorkTime = moment().tz(Timezone).format('HH:mm');
    setCurrentTime(newYorkTime);

    const fetchedTimezone = Timezone;

    // Extracting text after the first '/'
    const textAfterSlash = fetchedTimezone.substring(fetchedTimezone.indexOf('/') + 1);

    // Setting the extracted text as the timezone state
    setTimezone(textAfterSlash.replace('_', ' '));

    // Hardcoded content data
    const hardcodedData = [
      {
        "id": 1,
        "intro": "COMMUNICATION",
        "title": "Building the future of telco",
        "text": "Some say it is the most competitive industry in the world. That is why, you need to both be effective and efficient. It is all about customer satisfaction and loyalty.",
        "img_url": bannerData
      },
      {
        "id": 2,
        "intro": "JEWELLERY",
        "title": "Refining the future of jewellery and fashion",
        "text": "There is nothing more personal and jewellery. Data has to fuel personalization initiatives at scale by delivering tailored content and offers based on real time data and customer behavior in session on the website and in emails.",
        "img_url": bannerJew
      },
      {
        "id": 3,
        "intro": "RETAIL",
        "title": "Expanding the field of groceries",
        "text": "In retail, big data and especially product meta data allows for precise customer segmentation based on demographics, purchasing behavior, preferences, and lifestyle to create AI product recommendations.",
        "img_url": bannerRetail
      },
      {
        "id": 4,
        "intro": "MANUFACTURING",
        "title": "Constructing the next level of advanced manufacturing",
        "text": "The product can itself be a source of data. Internet of Things is on the rise, which means that products can capture data and become a more active part of providing insights about the customer journey and driving innovation.",
        "img_url": bannerManu
      },
      {
        "id": 5,
        "intro": "AUTOMOTIVE",
        "title": "Driving rapid change in the automotive industry",
        "text": "Deciding on the right car is difficult. It is balance of making an emotional and rational bond with the customer. Whether our customers want to lease of buy, it will always be a of combination of lifestyle and practical requirements.",
        "img_url": bannerAuto
      },
      {
        "id": 6,
        "intro": "MEDIA",
        "title": "Nurturing next-generation news delivery",
        "text": "In the subscription business model, data has a crucial role in attracting, retaining, and engaging subscribers.Data can help news media to cut through the noise, at the right time, in the right channel with the right message.",
        "img_url": bannerMedia
      },
      {
        "id": 7,
        "intro": "DESIGN",
        "title": "Forming the future of furniture",
        "text": "Design is about talking to the heart and mind of the customers. To understand these things on a deeper level, browsing history, purchase patterns, and interaction are all important parts getting a complete 360 view of the customer.",
        "img_url": bannerDesign
      }
    ];

    // Find content based on Pagetype
    const selectedContent = hardcodedData.find(item => item.id === Pagetype);
    setContent(selectedContent);
  }, [Pagetype]);

  const timerClassName = `banner-container-time-${TimerPosition}`;

  return (
  <>
    <div className={`${layout === 'normal' ? 'left-content' : 'right-content'} banner-container`}>
      {content && (
        <>
          <div className='banner-image'>
            <img src={content.img_url} />
            <div className='banner-border-mobile'></div>
          </div>
          <div className={layout === 'normal' ? 'right-text' : 'left-text'}>
            <div className='banner-text'>
              <span>{content.intro}</span>
              <h2>{content.title}</h2>
              <p>{content.text}</p>
            </div>
          </div>
          <div className={`${timerClassName} TimeClass`}>
            <span>{timezone}</span>
            <Clock time={currentTime} />
          </div>
        </>
      )}
    </div>
          <div className='banner-border'></div>
  </>
  );
}

export default Banner;