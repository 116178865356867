import React from "react";
import logo from "../assets/banner-visuels/clientsLogo/footer_logo.png"

function Footer () {
    return (
        

<footer class="bg-white dark:bg-gray-900">
    <div class="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8 footer-image">
        <div class="md:flex md:justify-center mb-12">
            <div class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 gap-4 text-left">

                <div class="sm:col-span-1 md:col-span-1 flex justify-center sm:justify-start">
                    <a href="#" class="flex items-center">
                        <img src={logo} class="h-20 me-3" alt="FlowBite Logo" />
                    </a>
                </div>

     
                <div class="text-center sm:text-left">
                    <h2 class="mb-2 text-md text-black uppercase dark:text-white">Social</h2>
                    <ul class="text-gray-400 font-light">
                        <li class="mb-4">
                            <a href="https://dk.linkedin.com/company/enterdeep" class="hover:underline">LinkedIn</a>
                        </li>
                    </ul>
                </div>


                <div class="text-center sm:text-left">
                    <h2 class="mb-2 text-md text-black uppercase dark:text-white">Contact</h2>
                    <ul class="text-gray-400 font-light">
                        <li class="mb-4">
                            <a href="tel:+4531563470" class="hover:underline">+45 31 56 34 70</a>
                        </li>
                    </ul>
                </div>


                <div class="text-center sm:text-left">
                    <h2 class="mb-2 text-md text-black uppercase dark:text-white">Products</h2>
                    <ul class="text-gray-400 font-light">
                        <li class="mb-4">
                            <a href="https://interfaceforsalesforce.com/" class="hover:underline">Interface for Salesforce</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="text-center">
            <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400 pb-20">
                Copyright © 2024 Deep. All rights reserved.
            </span>
        </div>
    </div>
</footer>

    )
}

export default Footer;