import React, { useState, useRef, useEffect } from 'react';
import dropdownIcon from "../assets/banner-visuels/Icon.png";

function Information () {
  const [visibleBlock, setVisibleBlock] = useState(null);
  const contentRefs = useRef([]);

  const toggleVisibility = (index) => {
    setVisibleBlock(visibleBlock === index ? null : index);
  };

  useEffect(() => {
    contentRefs.current = contentRefs.current.slice(0, hardcodedData.length);
  }, []);

  useEffect(() => {
    if (visibleBlock !== null) {
      const contentHeight = contentRefs.current[visibleBlock].scrollHeight;
      contentRefs.current[visibleBlock].style.height = `${contentHeight}px`;
    }
    contentRefs.current.forEach((ref, index) => {
      if (index !== visibleBlock) {
        ref.style.height = '0';
      }
    });
  }, [visibleBlock]);

  const hardcodedData = [
    {
      title: "Customer Data Platform",
      content: "Salesforce Customer Data Platform (CDP), also known as Data Cloud, is a comprehensive solution designed to help businesses unify, organize, and activate customer data across various channels and touchpoints. It empowers businesses to harness the full potential of their customer data, driving better engagement, loyalty, and revenue growth through personalized experiences and targeted marketing efforts."
    },
    {
      title: "Journeys",
      content: "Salesforce Marketing Cloud offers a wide range of digital orchestration possibilities, enabling businesses to create, manage, and optimize omni channel customer journeys in owned and paid media. Data from Sales Cloud, Service Cloud, Personalization and Advertising Studio are all relevant sources to enable and create journeys."
    },
    {
      title: "Data",
      content: "A 360 unified profile of each customer by consolidating data from multiple sources such as sales, marketing, service interactions, and third party platforms. The data foundation is designed for analytical purposes, allowing businesses to perform complex queries, analysis, and reporting to extract insights and make data driven decisions."
    },
  ];

  return (
    <div className="information">
      <div className="container">
        <div className="information--text text-center">
        OUR MISSION: 
        <br/>
        Empowering you to establish personalised relationships with your customers at scale.
        </div>
        <div className="information--dropdown" style={{display: 'none'}}>
          {hardcodedData.map((item, index) => (
            <div className='information-block' key={index} onClick={() => toggleVisibility(index)}>
              <div className='information-block--title'>
                {item.title}
                <span className={`information-block--icon ${visibleBlock === index ? 'rotate' : ''}`}>
                  <img src={dropdownIcon} alt="Dropdown Icon" />
                </span>
              </div>
              <div
                className='information-block--text'
                ref={(el) => contentRefs.current[index] = el}
                style={{
                  height: '0',
                  overflow: 'hidden',
                  transition: 'height 0.5s ease'
                }}
              >
                <div>{item.content}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Information;
