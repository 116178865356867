import React from "react";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import logo2 from '../assets/clients-visuels/Company=Aldi, Size=182.png';
import logo3 from '../assets/clients-visuels/Company=Audika, Size=182.png';
import logo4 from '../assets/clients-visuels/Company=Babyzen, Size=182.png';
import logo6 from '../assets/clients-visuels/Company=Demant, Size=182.png';
import logo7 from '../assets/clients-visuels/Company=EPOS, Size=182.png';
import logo9 from '../assets/clients-visuels/Company=Oticon, Size=182.png';
import logo10 from '../assets/clients-visuels/Company=Pandora, Size=182.png';
import logo12 from '../assets/clients-visuels/Company=Sixt, Size=182.png';
import logo13 from '../assets/clients-visuels/Company=Stokke, Size=182.png';
import logo14 from '../assets/clients-visuels/Company=TDC Erhverv, Size=182.png';
import logo15 from '../assets/clients-visuels/Company=Velux, Size=182.png';
import logo16 from '../assets/clients-visuels/Company=Volvo, Size=182.png';
import logo17 from '../assets/clients-visuels/Company=Össur, Size=182.png';


function Companies () {

    const settings = {
        infinite: true,
        speed: 1000,
        slidesToShow: 8,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0,
        dots: false,
        arrows: false, 
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 8,
              slidesToScroll: 1,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };

    const logos = [logo3, logo4, logo6, logo7, logo17, logo9, logo10, logo12, logo13, logo14, logo16];

    return (
        <div className="slider-container" style={{margin: "0 auto"}}>
        <Slider {...settings}>
          {logos.map((logo, index) => (
            <div key={index}>
              <img src={logo} alt={`Logo ${index + 1}`} style={{ width: '150px', padding: '40px 0' }} />
            </div>
          ))}
        </Slider>
      </div>
    )
}

export default Companies;