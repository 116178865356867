import React, { useState, useEffect } from 'react';
import "../css/cover.scss";
import saleforceLogo from "../assets/banner-visuels/Salesforce Cloud.png"




function Cover({ layout, TimerPosition, Pagetype, Timezone }) {
 

  return (<>
  <div className='cover'>
    <div className='container'>
        <div className='cover--left'>
          <div className='cover--block'>
            <h1>360° Expertise</h1>
            <p>We deliver operational excellence in CRM and Marketing Automation, empowering customer experiences from acquisition to long term retention.</p>
            <a href className='cover--button' style={{display: "none"}}>
              Let us help you
            </a>
          </div>
        </div>

          <div className='cover--right'>
              <div className='cover--circle'>
              <div className='cover--item'>Advertising Studio</div>
              <div className='cover--item'>Intelligence</div>
              <div className='cover--item'>Commerce Cloud</div>
              <div className='cover--item'>Marketing Cloud</div>
              <div className='cover--item'>Service Cloud</div>
              <div className='cover--item'>Salesforce Cloud</div>
              <div className='cover--item'>Data Cloud</div>
              <div className='cover--item'>Social Media</div>
              <div className='cover--saleforce'><img src={saleforceLogo} /> </div>
            </div>
        </div>
        
    </div>
  </div>
  </>
  );
}

export default Cover;