import React from "react";
import { Header, Cover, Ontology, Banner, Slider, Companies, Information, Contact, Footer } from "../components";
import "../css/index.scss";

function Homepage() {
    return (
        <div>
            <Header />
            <Cover/>
            <Companies />
            <Ontology />
              {/* <Slider />*/}
            <Banner layout="revert" TimerPosition="bottom-left" Timezone={"America/New_York"} Pagetype={1}/>
            <Banner layout="normal" TimerPosition="bottom-right" Timezone={"Europe/Paris"} Pagetype={2}/>
            <Banner layout="revert" TimerPosition="bottom-left" Timezone={"Europe/Madrid"} Pagetype={3}/>
            <Banner layout="normal" TimerPosition="bottom-right" Timezone={"Africa/Lagos"} Pagetype={4}/>
            <Banner layout="revert" TimerPosition="bottom-left" Timezone={"Europe/Berlin"} Pagetype={5}/>
            <Banner layout="normal" TimerPosition="bottom-right" Timezone={"Europe/London"} Pagetype={6}/>
            <Banner layout="revert" TimerPosition="bottom-left" Timezone={"Asia/Tokyo"} Pagetype={7}/>
            <Information />
              {/* <Contact />*/}
            <Footer />
        </div>
    );
}

export default Homepage;